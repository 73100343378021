

@media(min-width: 769px){
    .mobile-tablet-only{display: none !important;}


    .buy-now-nav-mobile{
        display: none;
    }

    .timer-bar{display: none;}

}

@media(max-width: 768px){   

    .desktop-only{
        display: none !important;
    }

    .buy-now-nav-desktop{
        display: none;
    }

    .app{padding-right: 0px;}

    .announcement-bar .plan, .announcement-bar .warning{
        display: none;
    }

    .navbar .navbar-buttons{
        display: none;
    }

    .timer-bar-desktop{display: none !important;}

    .bookmarks-and-search{
        flex-direction: column;
        row-gap: 1rem;        
        margin-top: 10px;
    }

    .bookmarks .sortable-item{
        width: calc(33% - 6px);
    }

    .bookmarks a{
        width: 100%;
    }

    .search{
        padding-left: 15px;
    }

    .todos-notes-links{
        flex-direction: column;
        row-gap: 1rem;
    }

    .sidebar{
        z-index: 4 !important;
        padding-bottom: 180px;
        border-left: 1px solid #e4e4e7;
    }

    .sidebar.true{
        width: 75% !important;
        position: fixed !important;
        z-index: 2;
        top: 50px;
        right: 0px;
        box-shadow: 0px 5px 5px grey;
    }

    .sidebar.false{
        width: 75% !important;
        position: fixed !important;
        z-index: 2;
        top: 80px;
        box-shadow: 0px 5px 5px grey;
        left: -100%
    }

    .todo-border, 
    /* .editor-wrapper,  */
    .expand-window,
    .section-resize,
    .resize-handle,
    .sidebar-grip{
        display: none !important;
    }

    .first-section{
        width: 100%;
        min-width: 100%;
        row-gap: 20px;
    }

    .first-container{
        height: auto !important;
    }

    .first-container-inner{
        flex-direction: column;
    }

    .first-container-inner .todolist{
        margin-bottom: 15px;
    }

    .w-60, .w-40{
        width: 100%;
    }

    .search-only{width: 100% !important}

    .homepage.d-flex{
        flex-direction: column;
    }
    .homepage .w-80, .homepage .disclaimer{
        width: 100%;
    }

    .search-only{
        margin: auto;
    }

    .search-only .d-flex {
        margin: auto;
        justify-content: center;
    }

    .announcement-bar{
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 4;
    }

    .hamburger-menu{
        color: white;
    }

    .navbar{
        /* position: fixed;
        width: 100%;
        top: 30px;
        z-index: 4; */
    }

    .timer-bar{
        margin-top: 80px;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
        background: #f9f9f9;
        padding: 5px 0px 10px 0px;
        border-bottom: 1px solid #e4e4e7;
    }

    /* .drag-handle{display: none !important;} */

    .youtube-inner{
        height: auto !important; 
        min-height:auto !important;
        padding-bottom: 25px !important;
        margin-left: 0px !important;
    }

    .editor-wrapper{
        min-width: 100vw !important;
        position: relative !important;
        right: auto !important;
    }

    .editor-resize, .expand-editor{display: none !important}

    .content{
        padding-left: 10px !important;
    }

    .content-inner{
        flex-direction: column;
        row-gap: 35px
    }

    .editor-padding{
        row-gap: 40px;
        padding: 5px 10px;
    }

    .add-a-video-card .search-options{
        margin-right: 0px !important;
    }

    .app{
        background: #f9f9f9;
        padding-left: 5px;
        padding-right: 15px
    }

}