.sidebar {
    width: 300px;
    max-width: 1250px;
    /* height: calc(100vh - 125px); */
    min-width: 18px !important;
    /* height: calc(100vh - 156px); */
    background-color: #f9f9f9;
    /* max-width: 250px;  */
    /* position: absolute;
    top: 81px;
    left: 0px; */

    border-bottom: 1px solid #e4e4e7;
    border-right: 1px solid #e4e4e7;
    text-align: left;
    padding: 20px 0px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    /* resize: horizontal; */
    overflow: hidden;
    overflow-y: scroll;

    position: relative;
    background: #fcfcfc;

    height: 100vh;
    padding-bottom: 300px;

    width: 250px !important;
    position: fixed !important;
    z-index: 2;
    top: 50px;
    right: 0px;
    box-shadow: 0px 5px 5px grey;

    padding-top: 10px;
}

.sidebar .folder{
    display: flex;
    /* margin-left: -10px;
    padding-left: 8px; */
    /* overflow: hidden; */
}

.sidebar-grip{
    color: #919eab;
    position: absolute;
    right: 0px;
    top: calc(50vh - 121px);

    display: flex;
    align-items: center;
    background: #e4e4e7;
    border-radius: 2px;

    padding: 3px 0px;
}

.resize-handle {
    width: 10px;
    cursor: ew-resize;
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    background-color: white;
    background: #fcfcfc;
}


.dnd-sortable-tree_simple_handle{
    cursor: grab;
    display: none;
}

.tree-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.delete-button {
    background-color: white;
    color: #919eab;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    opacity: 0;
}

.dnd-sortable-tree_simple_wrapper:hover .delete-button{opacity: 1;}
.pointer{cursor: pointer;}

.children {padding-left: 16px;}

.dnd-sortable-tree_simple_tree-item{padding: 5px 0px;border: 0px solid white;border-bottom: 1px solid #e4e4e7;}

.sidebar .item, .sidebar .page-inner{
    font-family: 'Geist Medium';
    font-size: 12px;
    line-height: 1rem;

    display: flex;
    align-items: center;
    column-gap: 5px;
    width: 100%;

    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar .item .item-text {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar button.add-folder-btn{
    font-family: "Geist SemiBold";
    font-size: 20px;
    padding: 0px 7px;
    padding-bottom: 2px;
    height: 25px;
    display: flex;
    align-items: center;
    color: black;
    background-color: white;
    margin-right: -7px;
    border-radius: 4px;
    margin-right: 2px;
    background: #fcfcfc;
}

.sidebar button.add-folder-btn:hover{
    transform: scale(.95);
    background: #EFEFEF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
}

.sidebar button.add-folder-btn:active{
    transform: scale(.90);
}


.sidebar .add-page-btn{
    font-family: "Geist Medium";
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 5px;

    border-bottom: 1px solid #e4e4e7;
    display: flex;
    /* justify-content: center; */
    padding: 2px 10px 4px 10px;
    border-radius: 6px;
}

.sidebar .page{
    padding-right: 10px;
    border-radius: 6px;
}

.sidebar .page-inner{
    width: 100%;
    padding: 6px 0px 6px 25px;
}

.resize-handle svg{
    position: absolute;
    color: #919eab;
    top: 4px;
    right: -4px;
    /* top: 50%; */
    /* transform: translate(-50%, -50%); */
    /* right: -12px; */
}

.folder-dropdown{
    cursor: pointer;
}

.folder-header:hover, 
.folder-pages .item:hover, .add-page-btn:hover{
    background-color: #f8fafc;
}

.sidebar .folder-menu, 
.sidebar .page-menu{
    display: flex;
    color: #919eab;
    background: #f8fafc;
    cursor: pointer;

    position: absolute;
    right: 0px;
    top: 8px;
    /* transform: translateY(-50%); */
}

.sidebar .folder-menu{
    width: 20px;
}

.sidebar .folder-menu:hover, 
.sidebar .page-menu:hover{
    color: black;
}

.sidebar .item a{
    width: 100%;
}

.sidebar .folder-pages-inner{
    display: flex;
    justify-content: start;
    width: 100%;
}

.sidebar .folder-pages-inner-wrapper{
    position: relative;
    text-align: left;
    display: flex;
    justify-content: start;
    width: 100%;
}

.sidebar .folder-pages-inner .folder-menu{
    right: 0px;
    top: 5px;
}

.sidebar .folder-pages-inner .tooltip-text{
    display: none;
}

/* .sidebar .folder-header:hover .folder-menu{
    opacity: 1;
} */

.home:hover{
    background-color: #f8fafc;
}

.folder{
    position: relative;
    padding: 0px 0px;
    cursor: pointer;
}

.folder .item{
    padding: 8px 0px;  
}

.pages, .page{position: relative;}

.folder-header:hover .folder-menutooltip,
.folder-pages .item:hover .folder-menutooltip{
    opacity: 1;
}

.folder-pages .add-page{
    font-family: 'Geist Medium';
    font-size: 11px;
    background: #fcfcfc;
    margin-left: 20px;
    border-radius: 6px;
    padding: 6px;
}


.folder-pages button:hover{background: #F8FAFC;}

.folder-menutooltip{
    opacity: 0;
    cursor: pointer;
}

/* Tooltip styling */
.tooltip {
    position: relative;
    cursor: pointer;
}

.tooltip-text {
    font-family: 'Geist SemiBold';
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the icon */
    left: 60%;
    transform: translateX(-60%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* Popup styling */
.popup-menu {
    position: absolute;
    top: 30px;
    right: -5px;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 8px;

    display: none;
    z-index: 2;

    flex-direction: column;
    row-gap: 10px;
}

.show-popup {
    display: flex;
}

.popup-menu button{
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
}

.popup-menu button:hover{
    background-color: #f4f4f5;
}


.upload-file, .upload-file p{
    font-size: 12px;
}

.sidebar .more-info{
    font-size: 11px;
}

.permanent-folder{
    margin-bottom: 5px;
}

.permanent-folder .item{
    padding-left: 5px !important;
}

.permanent-folder button{
    display: none;
    background-color: rgba(255,255,255,0);
    align-items: center;
    color: #919eab;
}

.permanent-folder:hover{
    background-color: #f8fafc;
}

.permanent-folder:hover button{
    display: flex;
}

.permanent-folder button:hover {
    color: black;
}

.bookmark-folder{
    white-space: nowrap;
    cursor: default;
}

.download-btn{
    background: black;
    color: white;
    font-family: 'Geist SemiBold';
    box-shadow: 0px 0px 5px black;
    white-space: nowrap;
}

.download-btn:hover{
    transform: scale(.98);
}

.download-btn:active{
    transform: scale(.96);
}

.delete-all-data-btn{
    border: 1px solid #e4e4e7;
    box-shadow: 0px 0px 5px grey;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

.delete-all-data-btn:hover{
    transform: scale(.98);
}

.delete-all-data-btn:active{
    transform: scale(.96);
}