.navbar{
    background-color: black;
    height: 50px;
    padding: 0px 20px;
    display: flex;
}

.navbar h3{
    font-family: "Geist Black";
    font-size: 1.2rem;
    letter-spacing: -.05em;
}

.logo-link{
    text-decoration: none;
}

.logo{
    color: white;
    margin: 0px;
    cursor: pointer;
}

.logo-subtitle{
    font-family: 'Geist Medium';
    font-size: 11px;
    color: #ccc;
    padding-left: 25px;
    margin-top: -5px;
}

.subtitle-text{
    font-family: 'Geist Regular';
    font-size: 11px;
    color: grey;
}

.free-trial{
    font-family: 'Geist Bold';
    font-size: 18px;
}

.access-token{
    font-family: "Geist SemiBold";
    font-size: 12px;

}

.buy-now-nav{
    background: hsl(221.2 83.2% 53.3%);
    font-family: 'Geist SemiBold';
    font-size: 14px;
    color: white;
    border-radius: 4px;
    padding: 10px 15px;
    box-shadow: 0px 0px 5px grey;
}

.buy-now-nav:hover{
    transform: scale(.98);
}

.buy-now-nav:active{
    transform: scale(.96);
}

.clock{
    width: 82px;
}