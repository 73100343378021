/* Generic Template */

.content{
    row-gap: 10px;
    padding-left: 20px;
}

.edit-title-input{
    font-family: 'Geist Bold';
    letter-spacing: -.025em;
    line-height: 2rem;
    font-size: 1.25em;
    margin: 0px 0px;
    padding: 0;
    max-width: 400px;
}

.sortable-section, .link-item, .drag-handle {
    touch-action: none;
}

.sortable-section{
    position: relative;
    background-color: white;
    border-radius: 6px;
}

/* .sortable-section .drag-handle.section{
    opacity: 0;
}

.sortable-section:hover .drag-handle.section{
    opacity: 1;
} */

.add-new-section-btn{
    font-size: 14px;
    border-radius: 6px;
    padding: 0px 8px;
    border: 1px solid #e4e4e7;
}

.drag-handle.section{
    position: absolute;
    top: 6px;
    left: -2px;
}

.drag-handle{
    padding: 0px;
    margin-left: 5px;
    min-width: 20px;
    min-height: 40px;
    /* border-right: 1px solid #e4e4e7; */
    /* background-color: #e4e4e7; */
}

.links-grid-container {
    padding: 10px 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    margin-top: 10px;
}

.link-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.link-item {
    width: 100%; /* Set a fixed width for each link item */
    display: flex;
    align-items: center;
    text-align: center;

    background-color: white;
    padding: 0px 0px;
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    position: relative; /* For tooltip positioning */

    min-height: 40px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);

    width: 100%;
    overflow: auto;
}

.link-item:hover {
    background-color: #f9f9f9;
    transform: scale(.98);
}

.link-item:active {
    background-color: #f9f9f9;
    transform: scale(.96);
}

/* .link-item .drag-handle, 
.link-item .delete-btn{
    opacity: 0;
}

.link-item:hover .drag-handle, 
.link-item:hover .delete-btn{
    opacity: 1;
} */

.link-text {
    font-family: 'Geist SemiBold';
    font-size: 14px;
    text-align: center;
    justify-content: center;
    height: 100%;

    flex-grow: 1;
    text-decoration: none;
    color: black;
    line-height: .9;

    /* max-width: 110px; */
    overflow: hidden;           /* Hide overflow text */
    /* white-space: nowrap; */
    text-overflow: ellipsis;    /* Show ellipsis for overflowed text */
}

.link-text:hover::after {
    content: attr(data-fulltext); /* Tooltip text set to full title */
    position: absolute;
    top: -25px;                  /* Position the tooltip above the link */
    left: 0;
    padding: 4px 8px;
    background-color: #333;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 4px;
    z-index: 1;
    opacity: 0.8;
}


.delete-btn {
    background: none;
    border: none;
    color: black;
    cursor: pointer;

    display: flex;
    align-items: center;
}


.add-link-form {
    display: flex;
    gap: 5px;
    /* margin-top: 10px; */
}

.add-link-form input[type="text"],
.add-link-form input[type="url"] {
    font-family: 'Geist Regular';
    padding: 5px;
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.add-link-form button {
    font-family: 'Geist Medium';
    padding: 10px;
    border: none;
    background-color: hsl(221.2 83.2% 53.3%);
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}

.open-link{
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 5px;
    border: 1px solid #e4e4e7;
    border-radius: 6px;
    padding: 0px 8px;
    margin-left: 10px;
}

.open-link-text{
    font-family: 'Geist Regular';
    font-size: 12px;
    margin-bottom: -3px;
}

.section-delete-button{
    /* display: none; */
    background-color: #f9f9f9;
    padding: 8px 8px;
    border-radius: 6px;
    border: 1px solid #e4e4e7;
    display: flex;
    align-items: center;
    column-gap: 2px;
    margin-left: auto;
    margin-top: 10px;
}

form.add-section-form {
    display: flex;
    justify-content: end;
    column-gap: 5px;
    margin-top: 5px;
    margin-right: 20px;
}

form.add-section-form input{
    border-radius: 6px;
    outline: none;
    border: 1px solid #e4e4e7;
    padding-left: 5px;
}

.info{
    font-family: 'Geist Medium';
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: start;
    column-gap: 5px;
    color: white;
    cursor: pointer;
}

.sidebar.false{
    display: none;
}


.sidebar h5{
    margin: 0px;
}

.sidebar h4{
    margin-bottom: 10px;
}

.updates-automatically-text{
    font-size: 11px;
    color: grey;
    margin-top: -5px;
}


@media(min-width: 769px){
    .header .open-link{
        margin-left: 10px;
    }

    .disclaimer{
        text-align: left;
    }

    .sidebar .sidebar-inner{
        padding-right: 0px;
    }

    .section-delete-button.desktop-only{
        margin: 0px;
        height: 36px;
    }
}

@media(max-width: 768px){
    .header{
        flex-direction: column;
        align-items: start;
    }

    .header-inner{
        width: 100%;
    }

    .add-link-form{
        width: 100%;
        flex-direction: column;
        margin-top: 10px;
    }

    .links-grid{
        grid-template-columns:repeat(2, 1fr);
    }

    .search-google-input{
        margin-top: 5px !important;
    }

    .content{
        padding-bottom: 300px;
    }

    .drag-handle.section {
        top: 6px;
    }

    form.add-section-form .edit-title-input{
        width: 100%;
    }

    form.add-section-form {
        padding: 0px 20px;
        margin-right: 0px;
    }

    .open-link{
        margin-right: 0px;
    }

    .add-new-section-btn{
        width: auto !important;
        padding: 0px 20px;
    }

    .spacing {
        background: #f9f9f9;
        height: 10px;
        width: calc(100% - 10px);
        border-radius: 6px;
    }

    .no-login-text{
        /* display: none; */
        position: absolute;
        right: 39px;
        color: #ccc;
    }

    .disclaimer{
        text-align: left;
    }

}
